import { Box, Button, Container, Stack, Typography, useTheme } from "@mui/material";
import { Header, HeaderOffset, HeroHeaderContainer, HeaderItem } from "@cm-websites/common";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Grid from "@mui/material/Unstable_Grid2";
import Image from "next/image";
import Mood from "../../../public/images/front-page/das-krimidinner-fuer-weihnachten.jpg";
import NextLink from "next/link";
import React from "react";
import styles from "./hero-header.module.css";

type HeroHeaderProps = { navItems: HeaderItem[] };

export const HeroHeader: React.FC<HeroHeaderProps> = ({ navItems = [] }) => {
  const theme = useTheme();

  const bgColor = "#031916";
  const mainCopy = (
    <Box sx={{ pt: { xs: 6, sm: 0 } }}>
      <Typography
        variant="h1"
        sx={{ color: "white", pb: { xs: 3, lg: 4 }, textAlign: { xs: "center", sm: "left" } }}
      >
        <Typography component="span" variant="overline" sx={{ display: "block" }}>
          Das Krimidinner für Zuhause
        </Typography>
        Advent, Advent, ein Grablicht brennt
      </Typography>
      <Typography
        variant="body1"
        paragraph
        sx={{ color: "white", textAlign: { xs: "center", sm: "left" } }}
      >
        Das wohlhabende Familienoberhaupt Dorothea Krone hatte, wie jedes Jahr, ihre Familie zu sich
        eingeladen, um gemeinsam Weihnachten zu feiern. Doch die sorgfältig gehegte Harmonie des Abends
        wurde jäh gestört, als die Hausherrin leblos auf dem Boden ihres Schlafzimmers aufgefunden wurde.
      </Typography>

      <Typography
        variant="body1"
        paragraph
        sx={{ color: "white", textAlign: { xs: "center", sm: "left" } }}
      >
        Das Krimidinner für die Weihnachtszeit für 4 – 6 Personen.
      </Typography>

      <Stack
        alignItems="center"
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 0.5, sm: 1 }}
        sx={{ pt: { xs: 1, sm: 3 } }}
      >
        <Button
          endIcon={<ChevronRightIcon />}
          href="/krimidinner/advent-advent-ein-grablicht-brennt"
          LinkComponent={NextLink}
          sx={{ width: { xs: "100%", sm: "unset" } }}
          color="secondary"
          variant="contained"
        >
          Fall entdecken
        </Button>
        <Button
          href="/wie-funktioniert-ein-krimidinner-fuer-zuhause"
          LinkComponent={NextLink}
          sx={{ color: "white", width: { xs: "100%", sm: "unset" } }}
          variant="text"
        >
          Was ist das?
        </Button>
      </Stack>
    </Box>
  );

  return (
    <HeroHeaderContainer backgroundColor={bgColor} id="frontpage-hero-header" sticky={false}>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
          justifyContent: "center",
          [theme.breakpoints.up("lg")]: {
            minHeight: "84vh",
          },
        }}
      >
        <Header bgColor="#016F6C" items={navItems} />
        <HeaderOffset />
        <Container
          maxWidth="xl"
          sx={{
            zIndex: 10,
            overflow: { xs: "hidden", lg: "unset" },
            pb: { xs: 4, sm: 8, lg: 6 },
            pt: { xs: 0, sm: 8, lg: 3 },
          }}
        >
          <Grid
            alignItems="center"
            container
            spacing={{ xs: 2, sm: 4, lg: 8 }}
            direction={{ xs: "column-reverse", sm: "row" }}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <Grid xs={12} sm={7} lg={5}>
              {mainCopy}
            </Grid>
          </Grid>
        </Container>
        <Box sx={{ display: { xs: "block", sm: "none" }, height: "50vh" }} />

        <Image
          className={styles.heroBgImage}
          src={Mood}
          alt="Das passende Krimidinner für Weihnachten"
          fill
          style={{
            objectFit: "cover",
            backgroundPosition: "right center",
            zIndex: -1,
          }}
        />
      </Box>
      <Container
        maxWidth="xl"
        sx={{
          display: { xs: "block", sm: "none" },
          background: "linear-gradient(0deg, rgba(3,25,22,1) 90%, rgba(0,0,0,0) 100%)",
          zIndex: 10,
          pb: { xs: 4, sm: 8, lg: 6 },
          overflow: "hidden",
          marginTop: "-30px",
        }}
      >
        <Box
          sx={{
            display: {
              xs: "block",
              sm: "hidden",
            },
          }}
        >
          {mainCopy}
        </Box>
      </Container>
    </HeroHeaderContainer>
  );
};
